'use strict';

if (! lemonade && typeof(require) === 'function') {
    var lemonade = require('lemonadejs');
}

if (! studio && typeof(require) === 'function') {
    var studio = require('@lemonadejs/studio');
}

function a0_0x14dc(e,k){const f=a0_0x2a28();return a0_0x14dc=function(p,a){return f[p-430]},a0_0x14dc(e,k)}(function(e,k){const f=a0_0x14dc;for(e=e();;)try{if(-parseInt(f(439))+parseInt(f(498))/2*(parseInt(f(458))/3)+-parseInt(f(431))/4+-parseInt(f(499))/5+-parseInt(f(488))/6+-parseInt(f(452))/7*(parseInt(f(470))/8)+parseInt(f(447))/9===k)break;else e.push(e.shift())}catch(p){e.push(e.shift())}})(a0_0x2a28,281409);
function a0_0x2a28(){const e=["guid","insertRow","completeMessage","getSource","7CmYacW","selected","tools","exports","'\n});\n\x3c/script>","function","312dZAPja","dropdown","intrasheets","record","type","instructions","goto","toString","test",'">\n                        <div class=\'jss_style_form_group\'>\n                            <label>Visible columns</label>\n                            <Dropdown :multiple="true" :autocomplete="true" :bind="self.columns" :ref="self.dropdown"/>\n                        </div>\n                        <div class=\'jss_style_form_group\'>\n                            <label>Instructions</label>\n                            <input type=\'text\' name="instructions" :bind="self.instructions" />\n                        </div>\n                        <div class=\'jss_style_form_group\'>\n                            <label>Thank you message</label>\n                            <textarea name="completeMessage" :bind="self.completeMessage" style=\'height:105px;\' ></textarea>\n                        </div>\n                        <div class=\'jss_style_form_group\'>\n                            <Switch text="Send me an email every new entry" :bind="self.emailOnCompletion"/>\n                        </div>\n                        <div class=\'jss_style_form_group\'>\n                            <Switch text="Limit one entry per user" :bind="self.oneEntryPerUser"/>\n                        </div>\n                        <br>\n                        <div class=\'jss_style_form_group\'>\n                            <input type=\'button\' value=\'Save\' class=\'jss_style_button\' onclick="self.save" />\n                        </div>\n                    </div>\n                    <div title="',
"Formifier",'<div class="jss_object">\n            <lm-modal :ref="self.modal" title="Formify" icon="save" width="680" height="540" :closed="true" :draggable="true" :closable="true" :overflow="true">\n                <div class="jss_style_p20">\n                <lm-tabs :selected="0" :ref="self.tabs">\n                    <div title="',"4389736bFlEKR","getConfig",'"><div :ref="self.form"></div></div>\n                    <div title="',"indexOf","onbeforesave","forms","reset","editors","worksheet",
"Preview","call","save","parse","tabs","Thanks for complete the form","client","oneEntryPerUser","rows","120486nKKhLQ","setConfig","url","logo","options","length","keys","stringify","open",'<div id="formify"></div>\n<script src="https://cdn.jsdelivr.net/npm/@jspreadsheet/formify@3/dist/index.min.js">\x3c/script>\n<script>\nformify(document.getElementById(\'formify\'), {\n\turl: \'',"10432DvKXTE","2549970JcnXen","link","add","hidden","233692gJxsvY","data","emailOnCompletion","line","undefined","parent",
"push","\">\n                        <div class='jss_style_form_group'>\n                            <label>Sharing though a public link</label>\n                            <input type='text' readonly=\"readonly\" value=\"{{self.url}}\">\n                        </div>\n                        <div class='jss_style_form_group'>\n                            <label>Embeddable code</label>\n                            <textarea readonly=\"readonly\" style='height:200px;'>{{self.code}}</textarea>\n                        </div>\n                    </div>\n                </lm-tabs>\n                </div>\n            </lm-modal>\n        </div>",
"332087qEvPWP","contextMenu","classList","Please fill the form below","columns","div","formify","amd","10874223SmLsoy"];a0_0x2a28=function(){return e};return a0_0x2a28()}
(function(e,k){const f=a0_0x14dc;"object"===typeof exports&&typeof module!==f(435)?module[f(455)]=k():typeof define===f(457)&&define[f(446)]?define(k):e.formifier=k()})(this,function(){let e=null;const k=function(a){return document.dictionary&&(a=document.dictionary[a]||a),a},f=function(){const a=a0_0x14dc;return{link:this[a(500)],columns:this.columns,instructions:this[a(463)],completeMessage:this[a(450)],emailOnCompletion:this[a(433)],oneEntryPerUser:this[a(486)]}},p=function(){const a=a0_0x14dc;
let b=this;const g=function(d){const c=a0_0x14dc;d=JSON[c(482)](JSON[c(495)](this[c(492)][c(443)][d]));return d[c(462)]===c(461)&&(d[c(462)]=c(459),d.source=e[c(477)].record[c(451)](this,d)),d};return b[a(481)]=function(){b[a(478)][a(489)]({formify:f[a(480)](b)});b[a(496)](b[a(478)])},b[a(496)]=function(d){b[a(478)]=d;let c=d.options[a(445)]||{},h={columns:[]};for(var n=0;n<d[a(492)][a(443)].length;n++)c[a(443)]&&-1!==c[a(443)][a(473)](""+n)?h.columns[a(437)](g.call(d,n)):h[a(443)][a(437)]({type:a(430)});
h[a(463)]=c.instructions||a(442);h[a(450)]=c[a(450)]||a(484);h.url="/api/"+d[a(436)].config[a(448)]+"/"+d[a(436)].worksheets[a(473)](d)+"/formify";!h[a(491)]&&(h[a(491)]="https://jspreadsheet.com/jspreadsheet/logo.png");h[a(474)]=function(l,r,m){l.form[a(476)]();l=Object[a(494)](r);m=[];for(let q=0;q<l[a(493)];q++)m[a(437)](r[l[q]]);return b[a(478)][a(449)]([{data:m}]),b[a(478)][a(464)](b[a(478)][a(487)].length),jSuites.notification({message:"Successfully saved"}),!1};e[a(475)][a(480)](d,b.form,h);
n=[];for(let l=0;l<d[a(492)][a(443)][a(493)];l++)n[a(437)]({value:l[a(465)](),text:d.getHeader(l)});b.dropdown[a(432)]=n;b.columns=c.columns||[];b[a(463)]=c[a(463)]||"";b[a(450)]=c[a(450)]||"";b[a(433)]=c[a(433)]||"";b[a(486)]=c[a(486)]||"";b[a(483)][a(453)]=0;e.intrasheets&&(d=e[a(460)][a(471)]())&&(b[a(490)]=d[a(485)].url+h[a(490)],b.code=a(497)+b[a(490)]+a(456))},a(469)+k("Config")+a(467)+k(a(479))+a(472)+k("Sharing")+a(438)};return function(a,b){const g=a0_0x14dc;e=this;let d={},c={};c[g(440)]=
function(h,n,l,r,m,q,t,u){return null==n&&null==l?m:(m[m[g(493)]-2][g(462)]!==g(434)&&m[g(437)]({type:g(434)}),m[g(437)]({title:g(468),onclick:function(){c.open(h)}}),m)};c[g(496)]=function(h){d.modal[g(496)]();d[g(496)](h)};b=document.createElement(g(444));return b[g(441)][g(501)](g(466)),a[g(454)].appendChild(b),lemonade.render(p,b,d),c}});
